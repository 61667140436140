import type { ReactElement, ReactNode } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import type { Theme } from '@/styles/theme';

interface StyleProps {
  theme: Theme;
}

const Style = createGlobalStyle<StyleProps>`
  * {
    box-sizing: border-box;
  }
  
  body {
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    font-weight: 400;
    background: ${({ theme }) => theme.palette.background};
    color: ${({ theme }) => theme.palette.dark};
    margin: 0;
    padding: 0;
    max-width: 100vw;
    height: 100%;
    overflow-x: hidden;
  }
  
  ol {
    margin-left: 12px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    
    li {
      line-height: 1.5;
    }
  }

  a {
    cursor: pointer;
    text-decoration: none;
    outline-color: ${({ theme }) => theme.palette.primary};
    outline-offset: 4px;
    color: ${({ theme }) => theme.palette.primary};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Montserrat, Helvetica, Arial, sans-serif;
    font-weight: 600;
    margin: 0;
    padding: 0;
    line-height: 1;
  }
  
  h1 {
    font-size: 26px;
    line-height: 30px;
    margin: 18px 0;
    
    @media ${({ theme }) => theme.media.tablet} {
      font-size: 36px;
      line-height: 40px;
      margin: 24px 0;
    }
  }
  
  h2 {
    font-size: 22px;
    line-height: 24px;
    margin: 10px 0;
    
    @media ${({ theme }) => theme.media.tablet} {
      font-size: 30px;
      line-height: 34px;
      margin: 24px 0 12px;
    }
  }
  
  h3 {
    font-size: 18px;
    line-height: 20px;
    margin: 18px 0 0 0;
  }
  
  h4 {
    font-size: 22px;
    line-height: 26px;
    margin: 12px 0;
  }
  
  h5 {
    font-size: 18px;
    line-height: 22px;
    margin: 12px 0;
  }
  
  h6 {
    font-size: 16px;
    line-height: 20px;
    margin: 12px 0;
  }
  
  p {
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 16px;
    margin: 8px 0;
    
    @media ${({ theme }) => theme.media.tablet} {
      margin: 12px 0;
    }
  }
  
  blockquote {
    border: 2px solid ${({ theme }) => theme.palette.secondary};
    border-radius: 6px;
    margin: 0 auto;
    padding: 15px 15px 15px 80px;
    position: relative;
    min-height: 70px;
    line-height: 1.5;
    
    &:before {
      content: '💡';
      position: absolute;
      left: 25px;
      font-size: 30px;
    }
  }
  
  button {
    border: none;
    background: none;
    font-size: 16px;
    cursor: pointer;
    outline-color: ${({ theme }) => theme.palette.primary};
    outline-offset: 4px;
  }
  
  input {
    border-color: ${({ theme }) => theme.palette.background};
    border-radius: 6px;
  }
  
  iframe {
    max-width: ${({ theme }) => theme.contentMaxWidth};
    width: 100%;
    height: 390px;
    object-fit: cover;
  }
  
  figure, picture, img {
    margin: 0;
    padding: 0;
  }
  
  figcaption {
    color: ${({ theme }) => theme.palette.dark};
    margin: 0;
    padding: 0;
    font-size: 14px;
  }
  
  hr {
    margin: 50px 0;
    
  }
`;

export const MaxWidthWrapper = styled.div`
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;
`;

export const ListingTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  margin-top: 50px;
`;

export const ListingSubtitle = styled.span`
  font-family: Roboto, Helvetica, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
  color: ${({ theme }) => theme.palette.gray};
`;

export const ListingTitle = styled.h1`
  margin: 5px 0;
`;

export const CardWrapper = styled.div<{
  children: ReactNode;
}>`
  margin-bottom: 20px;

  @media ${({ theme }) => theme.media.desktop} {
    margin-bottom: 30px;
  }
`;

export const ContentWrapper = styled.div`
  margin-top: 60px;
  margin-bottom: 120px;
  font-family: Roboto, Helvetica, sans-serif;

  p {
    font-size: 16px;
  }

  ul {
    font-size: 16px;
  }
`;

export const GeneralPageWrapper = styled.div`
  font-size: 18px;
  max-width: ${({ theme }) => theme.contentMaxWidth};
  margin: 0 auto;
  padding: 15px;

  li,
  span,
  a,
  p {
    line-height: 22px;
  }

  li {
    margin: 10px 0;
  }

  h1 {
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 50px;
  }

  h2 {
    font-size: 30px;
    line-height: 34px;
    margin: 24px 0 12px;
  }
`;

export const MobileCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  margin-top: 60px;

  div {
    width: 100%;
  }

  @media ${({ theme }) => theme.media.desktop} {
    display: none;
  }
`;

const GlobalStyle = (): ReactElement => <Style />;

export default GlobalStyle;
