import Image from '@atoms/Image';
import SearchInput from '@molecules/SearchInput';
import DropdownItem from '@organisms/Navigation/DropdownItem';
import Link from 'next/link';
import type { ReactElement } from 'react';

import type {
  DropdownItemProps,
  LinkItemProps,
  NavigationItemProps,
  NavigationProps,
} from '../Navgiation.types';
import { useDesktopNavigation } from './DesktopNavigation.hook';
import {
  BuyNowLink,
  ItemWrapper,
  LogoWrapper,
  NavigationContainer,
  NavigationItems,
  NavWrapper,
  SearchIcon,
  SearchIconWrapper,
  SearchInputWrapper,
  Separator,
} from './DesktopNavigation.styled';

const NavigationItem = ({
  item,
  dropdownRef,
  setIsOpen,
  isOpen,
}: NavigationItemProps): ReactElement => {
  switch (true) {
    case !!(item as LinkItemProps).link:
      return (
        <ItemWrapper>
          <Link href={(item as LinkItemProps).link}>
            {(item as LinkItemProps).title}
          </Link>
        </ItemWrapper>
      );
    default:
      return (
        <ItemWrapper ref={dropdownRef}>
          <DropdownItem
            linkItems={(item as DropdownItemProps).linkItems}
            title={item.title}
            mainLink={(item as DropdownItemProps).mainLink}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </ItemWrapper>
      );
  }
};

export const DesktopNavigation = ({
  navigationItems,
}: NavigationProps): ReactElement => {
  const {
    isOpen,
    setIsOpen,
    isSearchOpen,
    searchIconRef,
    searchInputRef,
    dropdownRef,
    setIsSearchOpen,
  } = useDesktopNavigation();

  return (
    <NavigationContainer>
      <NavWrapper>
        <LogoWrapper>
          <Link href="/">
            <Image
              alt="Logo FoxParadox. Mały narysowany lisek koloru czarnego obok napisu FoxParadox."
              src="/static/images/foxparadox-logo.svg"
              priority
              width={190}
              height={46}
            />
          </Link>
        </LogoWrapper>
        {navigationItems && navigationItems.length > 0 && (
          <NavigationItems>
            {navigationItems.map(item => (
              <NavigationItem
                key={`desktopNav-${item.title}`}
                item={item}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                dropdownRef={dropdownRef}
              />
            ))}
          </NavigationItems>
        )}
        <Separator />
        <SearchIconWrapper
          onClick={() => setIsSearchOpen(prevState => !prevState)}
          ref={searchIconRef}
          id="desktopSearchButton"
        >
          <SearchIcon />
          <span>Szukaj</span>
        </SearchIconWrapper>
        <Separator />
        <BuyNowLink href="/sklep/teneryfa/">Przewodnik po Teneryfie</BuyNowLink>
      </NavWrapper>
      {isSearchOpen && (
        <SearchInputWrapper>
          <SearchInput searchInputRef={searchInputRef} />
        </SearchInputWrapper>
      )}
    </NavigationContainer>
  );
};
