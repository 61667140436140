import Image from '@atoms/Image';
import SearchInput from '@molecules/SearchInput';
import Link from 'next/link';
import type { ReactElement } from 'react';
import { Fragment, useEffect, useRef, useState } from 'react';

import type {
  DropdownItemProps,
  LinkItemProps,
  NavigationProps,
} from '../Navgiation.types';
import {
  BurgerDash,
  BurgerWrapper,
  BuyNowLink,
  BuyNowLinkWrapper,
  DropdownItemWrapper,
  DropdownWrapper,
  LogoWrapper,
  NavigationContainer,
  NavigationItem,
  NavigationItems,
  NavWrapper,
  SearchIcon,
  SearchIconWrapper,
  SearchInputWrapper,
} from './MobileNavigation.styled';

export const MobileNavigation = ({
  navigationItems,
}: NavigationProps): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const [expandedItem, setExpandedItem] = useState<number>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!isSearchOpen) return;

    searchInputRef.current.focus();
  }, [isSearchOpen]);

  useEffect(() => {
    if (!isOpen) {
      document.body.style.overflow = 'auto';
      return;
    }

    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };

    document.body.style.overflow = 'hidden';

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen]);

  return (
    <NavigationContainer>
      <NavWrapper id="mobileNav">
        <SearchIconWrapper
          onClick={() => {
            if (isOpen) setIsOpen(false);
            setIsSearchOpen(prevState => !prevState);
          }}
          aria-label={
            isSearchOpen
              ? 'Zamknij pole wyszukiwania'
              : 'Otwórz pole wyszukiwania'
          }
          id="mobileSearchButton"
        >
          <SearchIcon />
        </SearchIconWrapper>
        <SearchInputWrapper isSearchOpen={isSearchOpen}>
          <SearchInput searchInputRef={searchInputRef} />
        </SearchInputWrapper>
        <LogoWrapper>
          <Link href="/">
            <Image
              alt="Logo FoxParadox. Mały narysowany lisek koloru czarnego obok napisu FoxParadox."
              src="/static/images/foxparadox-logo.svg"
              priority
              width={150}
              height={30}
            />
          </Link>
        </LogoWrapper>
        {navigationItems && navigationItems.length > 0 && (
          <NavigationItems isOpen={isOpen}>
            {navigationItems.map((item, idx) => {
              // Dropdown
              if ((item as DropdownItemProps).linkItems) {
                return (
                  <Fragment key={`mobileNav-${item.title}-${idx}`}>
                    <NavigationItem
                      hasSubElements={!(item as LinkItemProps).link}
                      isExpanded={expandedItem === idx}
                      onClick={() => {
                        if (expandedItem === idx) {
                          setExpandedItem(null);
                        } else {
                          setExpandedItem(idx);
                        }
                      }}
                    >
                      {item.title}
                    </NavigationItem>
                    <li>
                      <DropdownWrapper
                        isExpanded={expandedItem === idx}
                        numberOfElements={
                          (item as DropdownItemProps).linkItems.length
                        }
                      >
                        {(item as DropdownItemProps).linkItems?.map(
                          ({ link, title }) => (
                            <DropdownItemWrapper
                              key={`mobileNavDropdown-${title}-${idx}`}
                            >
                              <Link
                                href={link}
                                onClick={() => setIsOpen(false)}
                              >
                                {title}
                              </Link>
                            </DropdownItemWrapper>
                          ),
                        )}
                      </DropdownWrapper>
                    </li>
                  </Fragment>
                );
              }

              // Regular link
              return (
                <Fragment key={`mobileNav-${item.title}-${idx}`}>
                  <NavigationItem>
                    <Link
                      href={(item as LinkItemProps).link}
                      onClick={() => setIsOpen(false)}
                    >
                      {item.title}
                    </Link>
                  </NavigationItem>
                </Fragment>
              );
            })}
            <BuyNowLinkWrapper>
              <BuyNowLink href="/sklep/teneryfa/">
                Przewodnik po Teneryfie
              </BuyNowLink>
            </BuyNowLinkWrapper>
          </NavigationItems>
        )}
        <BurgerWrapper
          onClick={() => {
            setIsOpen(prevState => !prevState);
            setIsSearchOpen(false);
          }}
          isOpen={isOpen}
          aria-expanded={isOpen ? 'true' : 'false'}
          aria-label={isOpen ? 'Zamknij nawigację' : 'Otwórz nawigację'}
          aria-controls="mobileNav"
        >
          <BurgerDash />
          <BurgerDash />
          <BurgerDash />
        </BurgerWrapper>
      </NavWrapper>
    </NavigationContainer>
  );
};
