import { Search } from '@styled-icons/boxicons-regular/Search';
import Link from 'next/link';
import type { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { button } from '@/styles/shared';

export const NavWrapper = styled.nav<{
  children: ReactNode;
  id: string;
}>`
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  position: relative;
  background: white;

  ::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: white;
    z-index: -1;
  }
`;

export const LogoWrapper = styled.div`
  height: 30px;

  img {
    height: 100%;
    width: 100%;
  }
`;

export const NavigationItems = styled.ul<{
  children: ReactNode;
  isOpen: boolean;
}>`
  height: calc(100vh - 65px);
  width: 100vw;
  top: -100vh;
  position: absolute;
  overflow: scroll;
  transition: all 0.3s ease;
  z-index: -1;
  background: white;

  ${({ isOpen }) =>
    isOpen &&
    css`
      top: 65px;
    `};
`;

interface MobileNavigationItem {
  children: ReactNode;
  hasSubElements?: boolean;
  isExpanded?: boolean;
  onClick?: () => void;
}

export const NavigationItem = styled.li<MobileNavigationItem>`
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 20px;
  position: relative;
  cursor: pointer;
  font-family: Roboto, Helvetica, sans-serif;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.dark};

  a {
    color: ${({ theme }) => theme.palette.dark};
    width: calc(100% - 30px);
    height: 100%;
    display: flex;
    align-items: center;
  }

  ${({ hasSubElements, isExpanded }) =>
    hasSubElements &&
    css`
      &::after {
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 100%;
        background: ${({ theme }) => theme.palette.lightGray};
      }

      &::before {
        content: '';
        width: 6px;
        height: 6px;
        position: absolute;
        border: 2px solid ${({ theme }) => theme.palette.dark};
        right: 25.5px;
        top: 47%;
        z-index: 1;
        border-top: 0;
        border-right: 0;
        transform: ${
          isExpanded ? 'rotate(135deg)' : 'rotate(315deg) translate(2px, -2px)'
        };
        transition: all 0.3s ease;
        border-radius: 1px;
    `};

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      &::after {
        transform: rotate(180deg);
      }
    `}
`;

export const BurgerWrapper = styled.button<{
  'aria-controls': string;
  'aria-expanded': string;
  'aria-label': string;
  children: ReactNode;
  isOpen: boolean;
  onClick: () => void;
}>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  right: 10px;
  cursor: pointer;
  transform: rotate(0deg);
  overflow: hidden;

  & > div {
    transition: all 0.3s ease;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: translateY(3px);

      & > div {
        position: absolute;
        top: 40%;
        transform: translateX(50%);
        margin: 0;
      }

      & > div:first-of-type {
        transform: rotate(45deg);
      }

      & > div:last-of-type {
        transform: rotate(-45deg);
      }

      & > div:nth-of-type(2) {
        opacity: 0;
        width: 0;
      }
    `};
`;

export const BurgerDash = styled.div`
  height: 3px;
  width: 35px;
  background: ${({ theme }) => theme.palette.dark};
  border-radius: 2px;
  margin-top: 3px;
`;

export const SearchIconWrapper = styled.button<{
  'aria-label': string;
  children: ReactNode;
  id: string;
  onClick: () => void;
}>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  left: 10px;
  cursor: pointer;
`;

export const SearchIcon = styled(Search)`
  width: 33px;
  height: 33px;
  color: ${({ theme }) => theme.palette.dark};
`;

export const SearchInputWrapper = styled.div<{
  children: ReactNode;
  isSearchOpen: boolean;
}>`
  position: absolute;
  top: 75px;
  left: -200%;
  transition: all 0.3s ease;

  ${({ isSearchOpen }) =>
    isSearchOpen &&
    css`
      left: 50%;
      transform: translateX(-50%);
    `};
`;

export const DropdownWrapper = styled.ul<{
  children: ReactNode;
  isExpanded: boolean;
  numberOfElements: number;
}>`
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
  background: ${({ theme }) => theme.palette.superlightGray};

  ${({ isExpanded, numberOfElements }) =>
    isExpanded &&
    css`
      max-height: ${numberOfElements * 60}px;
    `};

  a {
    color: ${({ theme }) => theme.palette.dark};
  }
`;

export const DropdownItemWrapper = styled.li<{
  children: ReactNode;
}>`
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 40px;
  position: relative;
  cursor: pointer;
  font-family: Roboto, Helvetica, sans-serif;

  a {
    width: 90%;
    padding-block: 19px;
  }
`;

export const NavigationContainer = styled.div`
  display: block;

  @media ${({ theme }) => theme.media.largeDesktop} {
    display: none;
  }
`;

export const BuyNowLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const BuyNowLink = styled(Link)`
  ${button},
  height: 60px;
  width: fit-content;
  margin-top: 12px;
  display: flex;
`;
