import styled from 'styled-components';

export const PromobarWrapper = styled.div`
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid rgb(214, 213, 213);
  color: black;
  text-decoration: none;
  font-weight: 500;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  position: sticky;
  top: 0;
  z-index: 999;
  background: ${({ theme }) => theme.palette.background};
  transition: all 0.4s ease;

  @media ${({ theme }) => theme.media.desktop} {
    &:hover {
      animation: colorTransition 3s ease-in-out infinite;
      color: white;
    }

    @keyframes colorTransition {
      0% {
        background: #ff5858;
      }
      50% {
        background: #f09819;
      }
      100% {
        background: #ff5858;
      }
    }
  }
`;

export const CheckSpan = styled.span`
  padding-left: 6px;
  font-weight: 700;
  font-size: 12px;
`;

export const TextSpan = styled.span`
  display: inline-block;
  padding-inline: 6px;
  font-weight: 700;
  font-size: 12px;
`;
