import type { FormEventHandler, ReactNode } from 'react';

export enum VARIANT {
  PRIMARY,
  SECONDARY,
}

export enum SIZE {
  REGULAR,
  SMALL,
}

export interface ButtonProps {
  children: ReactNode;
  onClick?: () => void;
  onSubmit?: FormEventHandler<HTMLButtonElement>;
  size?: SIZE;
  variant?: VARIANT;
}
