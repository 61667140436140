import type { ReactElement } from 'react';

import { Button as StyledButton } from './Button.styled';
import type { ButtonProps } from './Button.types';
import { SIZE, VARIANT } from './Button.types';

export const Button = ({
  variant = VARIANT.PRIMARY,
  size = SIZE.REGULAR,
  children,
  onSubmit,
  onClick,
  ...rest
}: ButtonProps): ReactElement => (
  <StyledButton
    size={size}
    variant={variant}
    onSubmit={onSubmit}
    onClick={onClick}
    {...rest}
  >
    {children}
  </StyledButton>
);
