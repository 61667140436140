import Button from '@atoms/Button';
import { SIZE } from '@atoms/Button/Button.types';
import { StyledInput } from '@atoms/Input/Input.styled';
import { INPUT_TYPE } from '@atoms/Input/Input.types';
import type { ReactElement } from 'react';

import { SearchInputWrapper } from './SearchInput.styled';
import type { SearchInputProps } from './SearchInput.types';

export const SearchInput = ({
  searchInputRef,
}: SearchInputProps): ReactElement => (
  <SearchInputWrapper
    onClick={(e: MouseEvent) => e.stopPropagation()}
    action="/szukaj"
    method="get"
  >
    <StyledInput
      id="search-input"
      type={INPUT_TYPE.TEXT}
      placeholder="Wpisz czego szukasz..."
      maxLength={30}
      name="q"
      ref={searchInputRef}
      autoComplete="off"
    />
    <Button size={SIZE.SMALL}>🔍 Szukaj</Button>
  </SearchInputWrapper>
);
