import Link from 'next/link';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';

import {
  ButtonWrapper,
  ContentWrapper,
  CookieBannerButton,
  CookieBannerText,
  CookieBannerTitle,
  CookieBannerWrapper,
  ReadMoreLink,
} from './CookieBanner.styled';

export const CookieBanner = (): ReactElement => {
  const [cookiesAccepted, setCookiesAccepted] = useState<boolean>(true);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');

    if (!cookiesAccepted) {
      setCookiesAccepted(false);
      return;
    }
  }, []);

  if (cookiesAccepted) return null;

  return (
    <CookieBannerWrapper>
      <CookieBannerTitle>🍪 Cookies</CookieBannerTitle>
      <ContentWrapper>
        <CookieBannerText>
          Cześć! Chciałabym Cię poinformować, że ta strona używa plików Cookies
          w celach statystycznych. To pomaga mi ulepszać bloga! Kontynuując
          przeglądanie, wyrażasz zgodę na wykorzystanie Cookies. Pamiętaj,
          zawsze masz możliwość zarządzania tymi plikami poprzez ustawienia
          swojej przeglądarki.
          <Link href="/polityka-prywatnosci" passHref>
            <ReadMoreLink>Więcej informacji</ReadMoreLink>
          </Link>
        </CookieBannerText>
      </ContentWrapper>
      <ButtonWrapper>
        <CookieBannerButton
          onClick={() => {
            setCookiesAccepted(true);
            localStorage.setItem('cookiesAccepted', 'true');
          }}
        >
          Rozumiem
        </CookieBannerButton>
      </ButtonWrapper>
    </CookieBannerWrapper>
  );
};
