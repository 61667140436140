import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';

const Footer = dynamic(() => import('@organisms/Footer'));
import Navigation from '@organisms/Navigation';
import type { ReactElement } from 'react';

import { CookieBanner } from '@/components/atoms/CookieBanner/CookieBanner';
import { GlobalStyle, ThemeProvider } from '@/styles';

import { Promobar } from '../Promobar';
import { SkipButton } from './Layout.styled';
import type { LayoutProps } from './Layout.types';

export const Layout = ({
  children,
  navigation,
  footer,
}: LayoutProps): ReactElement => {
  const pathname = usePathname();
  const shouldShowPromobar = pathname !== '/sklep/teneryfa';

  return (
    <ThemeProvider>
      <GlobalStyle />
      <SkipButton as="a" href="#mainContent">
        Przejdź do treści
      </SkipButton>
      <SkipButton as="a" href="#desktopSearchButton">
        Przejdź do wyszukiwania
      </SkipButton>
      {shouldShowPromobar && <Promobar />}
      <Navigation navigationItems={navigation?.navigationItems} />
      <div id="mainContent">{children}</div>
      <CookieBanner />
      <Footer
        footerLinks={footer?.footerLinks}
        additionalLinks={footer?.additionalLinks}
      />
    </ThemeProvider>
  );
};
