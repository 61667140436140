import { Button } from '@atoms/Button/Button.styled';
import styled from 'styled-components';

import { button } from '@/styles/shared';

export const CookieBannerWrapper = styled.div`
  border-radius: 6px;
  position: fixed;
  bottom: 0;
  height: max-content;
  padding: 10px 0 20px 0;
  background: white;
  border: 1px solid ${({ theme }) => theme.palette.lightGray};
  z-index: 500;

  @media ${({ theme }) => theme.media.tablet} {
    width: 400px;
    left: 20px;
    padding: 20px;
    height: unset;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.media.tablet} {
    flex-direction: row;
    align-items: center;
  }
`;

export const CookieBannerButton = styled(Button)`
  ${button};
  width: 160px;
  border: 1px solid ${({ theme }) => theme.palette.primary};
  padding: 6px 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;

  @media ${({ theme }) => theme.media.tablet} {
    justify-content: flex-end;
  }
`;

export const CookieBannerText = styled.p`
  width: 100%;
  padding: 0 20px;
  font-size: 14px;
  margin: auto 0;

  @media ${({ theme }) => theme.media.tablet} {
    padding: 20px 0 10px;
  }
`;

export const CookieBannerTitle = styled.h3`
  font-size: 20px;
  margin: 0;
  text-align: center;
  padding: 25px 0 10px 0;

  @media ${({ theme }) => theme.media.tablet} {
    text-align: unset;
    padding: 0;
  }
`;

export const ReadMoreLink = styled.a`
  margin-left: 4px;
  text-decoration: underline;
  cursor: pointer;
  color: black;
`;
