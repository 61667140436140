import Link from 'next/link';
import type { ReactElement } from 'react';

import { CheckSpan, PromobarWrapper, TextSpan } from './Promobar.styled';

export const Promobar = (): ReactElement => (
  <Link href="/sklep/teneryfa">
    <PromobarWrapper>
      🚀 E-book{' '}
      <TextSpan>&quot;Teneryfa - Praktyczny przewodnik&quot;</TextSpan> już
      dostępny!
      <CheckSpan>🏝️ &nbsp;Sprawdź&nbsp;→</CheckSpan>
    </PromobarWrapper>
  </Link>
);
