import type { ReactNode } from 'react';
import styled from 'styled-components';

import { button } from '@/styles/shared';

export const SearchInputWrapper = styled.form<{
  action: string;
  children: ReactNode;
  method: string;
  onClick: (e: MouseEvent) => void;
}>`
  position: relative;
  width: 360px;
  max-width: 90vw;

  input {
    width: 100%;
    font-size: 16px;
    max-width: calc(100vw - 20px);
  }

  button {
    ${button};

    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    padding: 7px 16px;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 14px;
  }
`;
