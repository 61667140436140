import { Search } from '@styled-icons/boxicons-regular/Search';
import Link from 'next/link';
import type { ReactNode, RefObject } from 'react';
import styled from 'styled-components';

import { button } from '@/styles/shared';

export const NavWrapper = styled.nav`
  display: none;
  justify-content: flex-end;
  align-items: center;
  background: white;
  padding: 10px 30px;
  position: relative;

  @media ${({ theme }) => theme.media.largeDesktop} {
    display: flex;
  }
`;

export const LogoWrapper = styled.div`
  max-width: 236px;
  height: 46px;
  width: auto;
  position: absolute;
  left: 20px;

  img {
    width: 100%;
    height: 100%;
    padding: 4px 0;
  }
`;

export const NavigationItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NavigationItems = styled.ul`
  display: flex;
  align-items: center;
`;

export const ItemWrapper = styled.li<{
  children: ReactNode;
  ref?: RefObject<HTMLLIElement>;
}>`
  button {
    margin-right: 12px;

    @media ${({ theme }) => theme.media.largeDesktop} {
      margin-right: 20px;
    }
  }

  > a {
    color: ${({ theme }) => theme.palette.dark};
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 700;
    margin-right: 12px;
    padding: 3px 0;

    &:hover {
      color: ${({ theme }) => theme.palette.primary};
    }

    @media ${({ theme }) => theme.media.largeDesktop} {
      margin-right: 20px;
    }
  }

  &:last-child {
    > a {
      margin-right: 0;
    }
  }
`;

export const SearchIcon = styled(Search)`
  width: 22px;
  height: 22px;
  color: ${({ theme }) => theme.palette.dark};
`;

export const SearchIconWrapper = styled.button<{
  children: ReactNode;
  id: string;
  onClick: () => void;
  ref: RefObject<HTMLButtonElement>;
}>`
  display: flex;
  align-items: center;
  position: relative;

  span {
    display: none;
    font-size: 14px;
    margin-left: 5px;

    @media ${({ theme }) => theme.media.extraLargeDesktop} {
      display: block;
    }
  }
`;

export const Separator = styled.div`
  background: ${({ theme }) => theme.palette.lightGray};
  height: 40px;
  width: 1px;
  margin-inline: 16px;
`;

export const SearchInputWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 54px;
`;

export const NavigationContainer = styled.div`
  display: none;

  @media ${({ theme }) => theme.media.desktop} {
    display: block;
  }
`;

export const BuyNowLink = styled(Link)`
  ${button}
`;
